import { isValid, parse, parseISO } from 'date-fns';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

export function formatDate(date: string | Date) {
  try {
    if (!date) {
      return '';
    }

    return format(Date.parse(date.toString()), "dd 'de' MMMM 'de' yyyy", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

// Output: "dia mes ano"
export const formatDateWithShortMonth = inputDate => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('pt-BR', { month: 'short' });
  const year = date.getFullYear().toString().slice(-2);

  return `${day} ${month} ${year}`;
};

export const formatDateWithShortMonthComplete = inputDate => {
  const date = new Date(inputDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('pt-BR', { month: 'short' });
  const year = date.getFullYear().toString();

  return `${day} de ${month} ${year}`;
};

export function formatDateShort(date: string | Date) {
  try {
    if (!date) {
      return '';
    }

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, 'dd/MM/yyyy', { locale: ptBR });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortInputFormat(
  date: string | Date | undefined | null
): string {
  try {
    if (!date) {
      return '';
    }

    let dateObj: Date;

    if (date instanceof Date) {
      dateObj = date;
    } else if (typeof date === 'string') {
      // Try parsing the extended date string
      dateObj = parse(date, 'EEEE, MMMM d, yyyy', new Date(), { locale: ptBR });

      // If parsing fails, try another format or directly convert to Date if possible
      if (!isValid(dateObj)) {
        dateObj = new Date(date); // This works for ISO strings or other JS-readable formats
      }
    } else {
      throw new Error('Invalid date type');
    }

    // Check if the parsed date is valid
    if (!isValid(dateObj)) {
      throw new Error('Invalid date');
    }

    return format(dateObj, 'yyyy-MM-dd', { locale: ptBR });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortNoTimezone(date: string | Date | undefined): string {
  try {
    if (!date) return '';

    let year: number, month: number, day: number;

    if (typeof date === 'string') {
      // Tenta extrair o formato ISO: "YYYY-MM-DD" ou "YYYY-MM-DDTHH:mm:ss..."
      const isoMatch = date.match(/^(\d{4})-(\d{2})-(\d{2})/);
      if (isoMatch) {
        year = parseInt(isoMatch[1], 10);
        month = parseInt(isoMatch[2], 10);
        day = parseInt(isoMatch[3], 10);
      }
      // Verifica se a string começa com um formato de data tipo "Tue Apr 08 2025 ..."
      else if (/^[A-Za-z]{3}\s[A-Za-z]{3}\s\d{2}\s\d{4}/.test(date)) {
        const parts = date.split(' ');
        // parts[1] = mês abreviado, parts[2] = dia, parts[3] = ano
        const monthAbbr = parts[1];
        const dayStr = parts[2];
        const yearStr = parts[3];
        const months: { [key: string]: number } = {
          Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
          Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12
        };
        month = months[monthAbbr] || 0;
        day = parseInt(dayStr, 10);
        year = parseInt(yearStr, 10);
        if (month === 0) {
          throw new Error('Abreviação de mês inválida');
        }
      } else {
        throw new Error('Formato de data não reconhecido');
      }
    } else {
      // Se já for um objeto Date, usamos os getters da data local
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
    }

    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${year}`;
  } catch (error) {
    return `${date} is invalid to date`;
  }
}


export const formatDateShortInputFormatNoTimezone = (
  inputDate: Date | string
) => {
  return formatDateShortNoTimezone(inputDate).split('/').reverse().join('-');
};

export function formatDateTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "dd 'de' MMMM 'de' yyyy 'às' HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatDateShortTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "dd/MM/yyyy ' ' HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function formatTime(date: string | Date) {
  try {
    if (!date) {
      return '';
    }
    return format(new Date(date), "HH':'mm", {
      locale: ptBR
    });
  } catch (error) {
    return `${date} is invalid to date`;
  }
}

export function relativeTime(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  const formatter = new Intl.RelativeTimeFormat('pt-BR');
  const ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1
  };
  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (const key in ranges) {
    if (ranges[key] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key];
      return formatter.format(
        Math.round(delta),
        key as Intl.RelativeTimeFormatUnit
      );
    }
  }
}
